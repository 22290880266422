import { useState } from 'react';
import ReactCountDown from 'react-countdown';

const Countdown = ({ rsvpRef }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const onRSVPClick = (e) => {
    e.preventDefault();
    rsvpRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCompleted(true);
      // Render a completed state
      // return (
      //   <>
      //     <CameraWall />
      //   </>
      // );
    } else {
      // Render a countdown
      setIsCompleted(false);

      return (
        <>
          <div className='counter-column'>
            <span className='count'>{days}</span>
            <sub>Days</sub>
          </div>
          <div className='counter-column'>
            <span className='count'>{hours}</span>
            <sub>Hours</sub>
          </div>{' '}
          <div className='counter-column'>
            <span className='count'>{minutes}</span>
            <sub>Minutes</sub>
          </div>{' '}
          <div className='counter-column'>
            <span className='count'>{seconds}</span>
            <sub>Seconds</sub>
          </div>
          {/* <span>
            {hours}:{minutes}:{seconds}
          </span> */}
        </>
      );
    }
  };

  return (
    <>
      {!isCompleted && (
        <section className='countdown-section-two mt-5'>
          <div className='bg bg-pattern-4'></div>
          <div className='anim-icons'>
            <div className='icon-flower-3'></div>
            <div className='icon-flower-4'></div>
          </div>
          <div className='auto-container'>
            <div className='sec-title text-center style-two'>
              <span className='sub-title'>Stepping Into Forever</span>
              <h2>Join Us in Celebrating</h2>
            </div>
            <div className='outer-box wow fadeInUp'>
              <div className='time-counter'>
                <div className='time-countdown' data-countdown='03/09/2024'>
                  <ReactCountDown
                    date={new Date('2024-03-09T05:30:00.000Z').valueOf()}
                    renderer={renderer}
                  />
                </div>
                {/* <div className='btn-box'>
                  <a onClick={onRSVPClick} className='theme-btn btn-style-two'>
                    <span className='btn-title'>RSVP</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Countdown;
