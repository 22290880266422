const PrincipalSponsors = () => {
  return (
    <>
      <section id='timeline' className='event-section-two'>
        <div className='anim-icons full-width'>
          <div className='icon-flowers-6 bounce-y'></div>
          <div className='icon-leaves-5 bounce-x'></div>
          <div className='icon-leaves-6 bounce-x'></div>
        </div>
        <div className='auto-container'>
          <div className='sec-title text-center style-two'>
            {' '}
            <span className='sub-title'></span>
            <h2>Principal Sponsors</h2>
          </div>
          <div className='row'>
            <div className='sponsors-block-two col-lg-6 wow fadeInUp'>
              <div className='inner-box'>
                <ul>
                  <li>Michael Yanga</li>
                  <li>Ferdinand Limcangco</li>
                  <li>Jose Amisola Jr.</li>
                  <li>Lee Magpili</li>
                  <li>Arnold Ambi</li>
                  <li>Eliric Rivera</li>
                  <li>Jerson Val Saga</li>
                  <li>Eliazer Rodel Parulan</li>
                </ul>
              </div>
            </div>
            <div className='sponsors-block-two col-lg-6 wow fadeInUp'>
              <div className='inner-box'>
                <ul>
                  <li>Josette Biyo</li>
                  <li>Agnes Limcangco</li>
                  <li>Sigrid Aosas</li>
                  <li>Erlinda Cristobal</li>
                  <li>Mary Ann Lim</li>
                  <li>Ma. Daisy Demoni-Sison</li>
                  <li>Jemmalyn Miniao-Saga</li>
                  <li>Anne Chiara Amponin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrincipalSponsors;
