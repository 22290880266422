function Home() {
  return (
    <>
      {/* <section className='banner-section-three'>
        <div className='icon-bubbles-2'></div>
        <div className='icon-leaves-23 bounce-x'></div>
        <div className='icon-leaves-24 bounce-y'></div>
        <div className='bg-image wow fadeIn' data-wow-delay='1500ms'>
          <img src='assets/images/banner/LY01.png' alt='Image' />
        </div>
        <div className='auto-container'>
          <div className='row'>
            <div className='content-column col-lg-5 col-md-12 col-sm-12 wow fadeInLeft'>
              <div className='inner-column'>
                {' '}
                <span className='sub-title wow fadeInUp' data-wow-delay='900ms'>
                  Stepping Into Forever
                </span>
                <h2 className='name wow fadeInUp' data-wow-delay='1200ms'>
                  Ma. Nerissa Nicolas <br />
                  <span>&</span> <br />
                  Lemuel Francisco
                </h2>
                <div className='date wow fadeInUp' data-wow-delay='1500ms'>
                  MARCH 9, 2024
                </div>
                <div className='address wow fadeInUp' data-wow-delay='1800ms'>
                  <i className='icon fa fa-map-marker-alt'></i>Sta. Maria,
                  Bulacan Philippines
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section id='home' className='banner-section-two'>
        {/* <div className='icon-flowers-1 bounce-y'></div>
        <div className='icon-flowers-2 bounce-y wow' data-wow-delay='4s'></div> */}

        <div className='icon-bubbles-2'></div>
        <div className='icon-leaves-23 bounce-x'></div>
        <div className='icon-leaves-24 bounce-y'></div>
        <div className='bg-image wow fadeIn' data-wow-delay='1500ms'>
          <img src='assets/images/banner/banner-3.png' alt='Image' />
        </div>

        <div className='auto-container'>
          <div className='row'>
            <div className='content-column col-lg-5 col-md-12 col-sm-12 order-lg-2 wow fadeInUp'>
              <div className='inner-column'>
                <span className='sub-title wow fadeInUp' data-wow-delay='900ms'>
                  Stepping Into Forever
                </span>
                <h2 className='name wow fadeInUp' data-wow-delay='1200ms'>
                  Ma. Nerissa Nicolas <br />
                  <span>&</span> <br />
                  Lemuel Francisco
                </h2>
                <div className='row mt-2 mb-4'>
                  <span className='d-block'>
                    together with our beloved parents
                  </span>
                  <div className='col-lg-6'>
                    <div className='col-lg-12'>
                      <span className='d-lg-block text-left parents-name mt-2'>
                        Orlando Nicolas
                      </span>
                    </div>
                    <div className='col-lg-12'>
                      <span className='d-lg-block text-left parents-name mt-2'>
                        Ma. Elizabeth Nicolas
                      </span>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='col-lg-12'>
                      <span className='d-lg-block text-left parents-name mt-2'>
                        Reynaldo Francisco
                      </span>
                    </div>
                    <div className='col-lg-12'>
                      <span className='d-lg-block text-left parents-name mt-2'>
                        Leonila Francisco
                      </span>
                    </div>
                  </div>
                </div>
                <span className='d-block'>
                  cordially invite you to our wedding this
                </span>
                <div className='date wow fadeInUp' data-wow-delay='1500ms'>
                  MARCH 9, 2024
                  <small>
                    <br />
                    1:30PM
                  </small>
                </div>
                <div className='address wow fadeInUp' data-wow-delay='1800ms'>
                  <i className='icon fa fa-map-marker-alt'></i>Minor Basilica
                  and Parish of La Purísima Concepción <br />
                  Sta. Maria, Bulacan Philippines
                </div>
              </div>
            </div>
            {/* <div
              className='image-column col-lg-3 col-md-6 col-sm-12 wow fadeInLeft'
              data-wow-delay='600ms'
            >
              <div className='inner-column'>
                <div className='image-box'>
                  <figure className='image'>
                    <img src='assets/images/banner/banner2-1.jpg' alt='Image' />
                  </figure>
                </div>
              </div>
            </div>
            <div
              className='image-column style-two col-lg-3 col-md-6 col-sm-12 order-lg-3 wow fadeInRight'
              data-wow-delay='600ms'
            >
              <div className='inner-column'>
                <div className='image-box'>
                  <figure className='image'>
                    <img src='assets/images/banner/banner2-2.jpg' alt='Image' />
                  </figure>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
