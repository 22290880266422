import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Upload from './Upload';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CameraPage from './Camera/Camera';
import GalleryPage from './GalleryPage';
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/upload',
    element: <Upload />,
  },
  {
    path: '/camera',
    element: <CameraPage />,
  },
  {
    path: '/gallery',
    element: <GalleryPage />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />

    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
