const Map = () => {
  return (
    <section className='map-section'>
      {/* <div className='icon-flower-22 bounce-x'></div> */}
      <div className='auto-container'>
        <div className='sec-title text-center'>
          <h2>Location</h2>
        </div>
      </div>
      <div className='row px-5'>
        <div className='services-block-two col-lg-4 offset-lg-2 wow fadeInUp'>
          <div className='inner-box'>
            <div className='image-box'>
              <figure className='image'>
                <a
                  target='_blank'
                  href='https://www.waze.com/live-map/directions/ph/central-luzon/santa-maria/minor-basilica-and-parish-of-la-purisima-concepcion?to=place.ChIJ5-681VKslzMR1opbIT5qua8'
                >
                  <img src='assets/images/church.png' alt='Image' />
                </a>
              </figure>
            </div>
            <div className='content-box'>
              <span className='sub-title'>The Ceremony</span>
              <h4 className='title'>
                <a
                  target='_blank'
                  href='https://www.waze.com/live-map/directions/ph/central-luzon/santa-maria/minor-basilica-and-parish-of-la-purisima-concepcion?to=place.ChIJ5-681VKslzMR1opbIT5qua8'
                >
                  Minor Basilica and Parish of La Purísima Concepción
                </a>
                <div className='text'>Santa Maria, 3022 Bulacan</div>
              </h4>
            </div>
          </div>
        </div>

        <div
          className='services-block-two col-lg-4 wow fadeInUp'
          data-wow-delay='300ms'
        >
          <div className='inner-box'>
            <div className='image-box'>
              <figure className='image'>
                <a
                  target='_blank'
                  href='https://www.waze.com/live-map/directions/angel-gabriels-garden-pavilion-santa-maria?to=place.w.79298708.792724936.10401205'
                >
                  <img src='assets/images/reception.png' alt='Image' />
                </a>
              </figure>
            </div>
            <div className='content-box'>
              <span className='sub-title'>The Reception</span>
              <h4 className='title'>
                <a target='_blank' href='page-events.html'>
                  Angel Gabriel Garden
                </a>
                <div className='text'>
                  146 JP Rizal St, Santa Maria, 3022 Bulacan
                </div>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 wow fadeInUp'>
          <div className='inner-box'></div>
          <iframe
            width='100%'
            height='600'
            className='map w-100'
            loading='lazy'
            allowFullScreen
            src="https://www.google.com/maps/embed/v1/directions?zoom=15&origin=Minor%20Basilica%20and%20Parish%20of%20La%20Pur%C3%ADsima%20Concepci%C3%B3n&destination=Angel%20Gabriel's%20Garden&key=AIzaSyBhMq79IbF_YIHb-rKvOaQWDMtZaf8p5k4"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Map;
