const SecondarySponsors = () => {
  return (
    <section id='news' className='news-section'>
      <div className='icon-leave-4 bounce-y'></div>
      <div className='icon-flower-12 zoom-one'></div>
      <div className='icon-flower-22 bounce-x'></div>
      <div className='auto-container'>
        <div className='sec-title text-center style-two'>
          <h2>Secondary Sponsors</h2>
        </div>

        <div className='row'>
          <div className='sponsors-block-two col-lg-6 wow fadeInUp'>
            <div className='inner-box'>
              <ul>
                <li>
                  <span className='sponsors-secondary-text'>Best Man</span>
                  Virgilio delos Reyes
                </li>
                <li>
                  <span className='sponsors-secondary-text'>Candle</span>
                  Jake Hersalia
                </li>
                <li>
                  <span className='sponsors-secondary-text'>Cord</span>
                  Isagani Roque
                </li>
                <li>
                  <span className='sponsors-secondary-text'>Veil</span>
                  Jan Robert Galang
                </li>
                <li>
                  <span className='sponsors-secondary-text'>Groomsmen</span>
                </li>
                <li>Gabriel Angelo Pechardo</li>
                <li>Frederick Ilanan</li>
                <li>Alvin John de Leon</li>
                <li>Ramikert del Prado</li>
              </ul>
            </div>
          </div>
          <div className='sponsors-block-two col-lg-6 wow fadeInUp'>
            <div className='inner-box'>
              <ul>
                <li>
                  <span className='sponsors-secondary-text'>Maid of Honor</span>
                  Jessalyne Nicolas
                </li>
                <li>
                  <span className='sponsors-secondary-text'>Candle</span>
                  Mariel Junio
                </li>
                <li>
                  <span className='sponsors-secondary-text'>Cord</span>
                  Crisza Marienella Bernardo
                </li>
                <li>
                  <span className='sponsors-secondary-text'>Veil</span>
                  Ma. Ellaine Cruz
                </li>
                <li>
                  <span className='sponsors-secondary-text'>Bridesmaids</span>
                </li>
                <li>Kezzia Malones</li>
                <li>Gabrielle Amisola</li>
                <li>Carmina Francisco</li>
                <li>Noreen Francisco</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='row text-center mt-5'>
          <div className='sponsors-block-two col-lg-4 wow fadeInUp'>
            <span className='sponsors-secondary-text'>Ring Bearer</span>
            <p className='sponsors-primary-text'>Jose Orlando Nicolas</p>
          </div>

          <div className='sponsors-block-two col-lg-4 wow fadeInUp'>
            <span className='sponsors-secondary-text'>Coin Bearer</span>
            <p className='sponsors-primary-text'>Lanel dela Rosa</p>
          </div>

          <div className='sponsors-block-two col-lg-4 wow fadeInUp'>
            <span className='sponsors-secondary-text'>Bible Bearer</span>
            <p className='sponsors-primary-text'>Jevon Jie Rui Loh</p>
          </div>
        </div>

        <div className='row text-center mt-5'>
          <div className='sponsors-block-two'>
            <div className='inner-box'>
              <ul>
                <li>
                  <span className='sponsors-secondary-text'>Flower Girls</span>
                </li>
                <li>Cara Christiana Ngo</li>
                <li>Sasha Claire Ngo</li>
                <li>Arya Nicolas</li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className='news-block wow fadeInUp'>
          <div className='inner-box'>
            <div className='image-box'>
              <figure className='image'>
                <a href='news-details.html'>
                  <img src='images/resource/news1-1.jpg' alt='Image' />
                </a>
              </figure>
            </div>
            <div className='content-box'>
              <div className='date'>
                <span>24</span>December 2024
              </div>
              <div className='title-box'>
                <h6 className='title'>
                  <a href='news-details.html'>
                    Couple's wedding <br />
                    turns emotional as <br />
                    groom stands
                  </a>
                </h6>
                <div className='text'>
                  I'm filled with joy and excitement to be <br />
                  marrying the love of my life.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='news-block style-two wow fadeInUp'
          data-wow-delay='200ms'
        >
          <div className='inner-box'>
            <div className='image-box'>
              <figure className='image'>
                <a href='news-details.html'>
                  <img src='images/resource/news1-2.jpg' alt='Image' />
                </a>
              </figure>
            </div>
            <div className='content-box'>
              <div className='date'>
                <span>24</span>December 2024
              </div>
              <div className='title-box'>
                <h6 className='title'>
                  <a href='news-details.html'>
                    Photography is the <br />
                    important part of <br />
                    wedding.
                  </a>
                </h6>
                <div className='text'>
                  I'm filled with joy and excitement to be <br />
                  marrying the love of my life.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='btn-box text-center'>
          {' '}
          <a
            href='news-grid.html'
            className='theme-btn btn-style-two yellow-bg'
          >
            <span className='btn-title'>View all News</span>
          </a>{' '}
        </div> */}
      </div>
    </section>
  );
};

export default SecondarySponsors;
