import React, { useState, useRef, useEffect } from 'react';
import { app, storage } from './firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';

const imageMimeType = /image\/(png|jpg|jpeg)/i;
const DB = getFirestore(app);

const Upload = () => {
  const [status, setStatus] = useState('idle');
  const [imgUrl, setImgUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const [progresspercent, setProgresspercent] = useState(0);
  const hiddenFileInput = useRef(null);

  const saveToFirebase = async (data) => {
    try {
      const imagesCollection = collection(DB, 'images');
      const imageDoc = doc(imagesCollection);
      setDoc(imageDoc, data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const changeHandler = (e) => {
    setStatus('preview');
    setProgresspercent(0);
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert('Image mime type is not valid');
      return;
    }
    setFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const file = e.target[0]?.files[0];
    if (!file) return;
    const storageRef = ref(
      storage,
      `event/${new Date().valueOf()}_${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    setStatus('upload');

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        setStatus('idle');
        alert(error);
      },
      () => {
        setStatus('complete');

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const metadata = uploadTask.snapshot.metadata;

          setImgUrl(downloadURL);
          const data = {
            downloadUrl: downloadURL,
            message: message,
            createdAt: new Date().valueOf(),
            updatedAt: new Date(metadata.updated).valueOf(),
          };
          saveToFirebase(data);
        });
      }
    );
  };

  const handleReset = () => {
    setStatus('idle');
    setFile(null);
    setImgUrl(null);
    setProgresspercent(0);
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setImgUrl(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  return (
    <>
      <form onSubmit={handleSubmit} className='form'>
        <input
          type='file'
          className='hidden'
          ref={hiddenFileInput}
          onChange={changeHandler}
          accept='image/*'
          capture='environment'
        />
        <div className='page-wrapper'>
          <section id='upload' className='banner-section-two upload-page'>
            <div className='auto-container'>
              <div className='sec-title text-center'>
                <h3>Share your memories with us</h3>
              </div>
              <div className='row'>
                <div className='content-column col-lg-12 col-md-12 col-sm-12 order-lg-2 wow fadeInUp'>
                  <div className='inner-column'>
                    <div className='upload-block col-lg-12 wow fadeInLeft'>
                      <div className='inner-box'>
                        {imgUrl && (
                          <>
                            <div
                              className='image-preview'
                              onClick={handleClick}
                            >
                              <img
                                src={imgUrl}
                                alt='uploaded file'
                                height={200}
                              />
                            </div>
                            {status === 'preview' && (
                              <div className='message-box form-group col-lg-12'>
                                <div className='input-outer'>
                                  <textarea
                                    name='message'
                                    placeholder='Message'
                                    onChange={(e) => setMessage(e.target.value)}
                                    rows={5}
                                  ></textarea>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {imgUrl && status === 'complete' && (
                          <div className='upload-success my-5'>
                            Upload Success.
                          </div>
                        )}

                        {imgUrl && status === 'complete' && (
                          <div className='row text-center my-5'>
                            <a className='view-image-url' href='/gallery'>
                              View Images
                            </a>
                          </div>
                        )}

                        <div className='image-box'>
                          <div className='image'>
                            {imgUrl && status === 'upload' && (
                              <>
                                <span>{progresspercent}%</span>
                              </>
                            )}

                            {/* <span>
                              <div className='outerbar'>
                                <div
                                  className='innerbar'
                                  style={{ width: `${100}%` }}
                                >
                                  {0}%
                                </div>
                              </div>
                            </span> */}

                            {status === 'idle' ? (
                              <span onClick={handleClick}>Browse</span>
                            ) : (
                              ''
                            )}

                            {status === 'preview' ? (
                              <span>
                                <button className='submit' type='submit'>
                                  Upload
                                </button>
                              </span>
                            ) : (
                              ''
                            )}

                            {imgUrl && status === 'complete' && (
                              <span
                                className='upload-again'
                                onClick={handleReset}
                              >
                                Upload Again?
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    </>
  );
};

export default Upload;
