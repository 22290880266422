// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBKjZvY7TmooRkg1413dxsB_thZaSJBHqc',
  authDomain: 'lem-and-ysai.firebaseapp.com',
  projectId: 'lem-and-ysai',
  storageBucket: 'lem-and-ysai.appspot.com',
  messagingSenderId: '323435958719',
  appId: '1:323435958719:web:8db04199956b69448fd10a',
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
