import React, { useEffect, useState } from 'react';
import { app } from './firebase';

import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
const DB = getFirestore(app);

const GalleryPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [imageGroup, setImageGroup] = useState([]);

  const chunkMaxLength = (arr, chunkSize, maxLength) => {
    return Array.from({ length: maxLength }, () => arr.splice(0, chunkSize));
  };

  useEffect(() => {
    const getImages = async () => {
      const imagesCollection = collection(DB, 'images');
      const q = query(imagesCollection, orderBy('createdAt', 'desc'));
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const docImgs = [];
          querySnapshot.forEach((doc) => {
            docImgs.push(doc.data());
          });
          console.log(docImgs, Math.round(docImgs / 6));
          setImageGroup(
            chunkMaxLength(docImgs, 6, Math.round(docImgs.length / 6))
          );
        },
        (error) => {
          console.log(error);
        }
      );

      //   const querySnapshot = await getDocs(q);
      //   setImageDocs(
      //     querySnapshot.docs.map((doc) => {
      //       // const data = doc.data();
      //       // const userData = await getDoc(data.user);
      //       // data.user = userData;
      //       return { uid: doc.id, ...doc.data() };
      //     })
      //   );
    };
    getImages();
  }, []);

  // useEffect(() => {
  //   console.log(imageGroup);
  // }, [imageGroup]);

  return (
    <>
      {imageGroup.length !== undefined ? (
        <section className='gallery-section-three'>
          <div className='bg bg-pattern-5 bounce-y'></div>

          <div className='auto-container'>
            <div class='sec-title text-center style-two'>
              <span class='sub-title'>
                Thank you for sharing your memories with us
              </span>
              <h2>Gallery</h2>
            </div>
            <div className='row'>
              {imageGroup.map((group) => {
                return (
                  <>
                    <div className='row'>
                      <div className='left-column col-lg-6 col-md-12 col-sm-12 wow fadeInLeft'>
                        <div className='row'>
                          {group[0] && (
                            <div className='gallery-block-three col-lg-6 col-md-6 col-sm-6 image01'>
                              <div className='inner-box'>
                                <figure className='image overlay-anim'>
                                  <a
                                    href={group[0].downloadUrl}
                                    className='lightbox-image'
                                    data-fancybox='gallery'
                                  >
                                    <img
                                      src={group[0].downloadUrl}
                                      alt='Image'
                                    />
                                  </a>
                                </figure>
                              </div>
                            </div>
                          )}
                          {group[1] && (
                            <div className='gallery-block-three col-lg-6 col-md-6 col-sm-6 image01'>
                              <div className='inner-box'>
                                <figure className='image overlay-anim'>
                                  <a
                                    href={group[1].downloadUrl}
                                    className='lightbox-image'
                                    data-fancybox='gallery'
                                  >
                                    <img
                                      src={group[1].downloadUrl}
                                      alt='Image'
                                    />
                                  </a>
                                </figure>
                              </div>
                            </div>
                          )}
                          {group[2] && (
                            <div className='gallery-block-three col-lg-12 col-md-12 col-sm-12 image02'>
                              <div className='inner-box'>
                                <figure className='image overlay-anim'>
                                  <a
                                    href={group[2].downloadUrl}
                                    className='lightbox-image'
                                    data-fancybox='gallery'
                                  >
                                    <img
                                      src={group[2].downloadUrl}
                                      alt='Image'
                                    />
                                  </a>
                                </figure>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='right-column col-lg-6 col-md-12 col-sm-12 wow fadeInRight'>
                        <div className='row'>
                          {group[3] && (
                            <div className='gallery-block-three col-lg-12 col-md-12 col-sm-12 image02'>
                              <div className='inner-box'>
                                <figure className='image overlay-anim'>
                                  <a
                                    href={group[3].downloadUrl}
                                    className='lightbox-image'
                                    data-fancybox='gallery'
                                  >
                                    <img
                                      src={group[3].downloadUrl}
                                      alt='Image'
                                    />
                                  </a>
                                </figure>
                              </div>
                            </div>
                          )}
                          {group[4] && (
                            <div className='gallery-block-three col-lg-6 col-md-6 col-sm-6 image01'>
                              <div className='inner-box'>
                                <figure className='image overlay-anim'>
                                  <a
                                    href={group[4].downloadUrl}
                                    className='lightbox-image'
                                    data-fancybox='gallery'
                                  >
                                    <img
                                      src={group[4].downloadUrl}
                                      alt='Image'
                                    />
                                  </a>
                                </figure>
                              </div>
                            </div>
                          )}
                          {group[5] && (
                            <div className='gallery-block-three col-lg-6 col-md-6 col-sm-6 image01'>
                              <div className='inner-box'>
                                <figure className='image overlay-anim'>
                                  <a
                                    href={group[5].downloadUrl}
                                    className='lightbox-image'
                                    data-fancybox='gallery'
                                  >
                                    <img
                                      src={group[5].downloadUrl}
                                      alt='Image'
                                    />
                                  </a>
                                </figure>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default GalleryPage;
