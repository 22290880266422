import React from 'react';

const SaveTheDate = () => {
  return (
    <>
      <section className='countdown-section-two'>
        <div className='auto-container'>
          {/* <div className='sec-title text-center style-two'>
            <h2>Save the date</h2>
          </div> */}
          <div className='outer-box wow fadeInUp video-player'>
            <video
              src='assets/videos/01.mp4'
              controls={true}
              autoPlay={true}
              muted={true}
              width={'100%'}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default SaveTheDate;
