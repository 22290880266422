function Details() {
  return (
    <>
      <section id='event' className='services-section-two'>
        <div className='auto-container'>
          <div className='sec-title text-center'>
            <h2>Details</h2>
          </div>

          <div className='row'>
            <div className='services-block-two col-lg-6 wow fadeInUp'>
              <div className='inner-box'>
                <div className='image-box'>
                  <figure className='image'>
                    <a
                      target='_blank'
                      href='https://www.waze.com/live-map/directions/ph/central-luzon/santa-maria/minor-basilica-and-parish-of-la-purisima-concepcion?to=place.ChIJ5-681VKslzMR1opbIT5qua8'
                    >
                      <img
                        src='assets/images/resource/services2-1.png'
                        alt='Image'
                      />
                    </a>
                  </figure>
                </div>
                <div className='content-box'>
                  <span className='sub-title'>The Ceremony</span>
                  <h4 className='title'>
                    <a
                      target='_blank'
                      href='https://www.waze.com/live-map/directions/ph/central-luzon/santa-maria/minor-basilica-and-parish-of-la-purisima-concepcion?to=place.ChIJ5-681VKslzMR1opbIT5qua8'
                    >
                      Minor Basilica and Parish of La Purísima Concepción
                    </a>
                  </h4>
                  <div className='text'>1:30PM</div>
                </div>
              </div>
            </div>

            <div
              className='services-block-two col-lg-6 wow fadeInUp'
              data-wow-delay='300ms'
            >
              <div className='inner-box'>
                <div className='image-box'>
                  <figure className='image'>
                    <a
                      target='_blank'
                      href='https://www.waze.com/live-map/directions/angel-gabriels-garden-pavilion-santa-maria?to=place.w.79298708.792724936.10401205'
                    >
                      <img
                        src='assets/images/resource/services2-2.png'
                        alt='Image'
                      />
                    </a>
                  </figure>
                </div>
                <div className='content-box'>
                  <span className='sub-title'>The Reception</span>
                  <h4 className='title'>
                    <a target='_blank' href='page-events.html'>
                      Angel Gabriel Garden
                    </a>
                  </h4>
                  <div className='text'>4:00PM</div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-5'>
            {/* <div className='services-block-two col-lg-12 wow fadeInUp'>
              <div className='sec-title text-center title-box'>
                <h4 className='title'>Dress Code</h4>
              </div>
              <div className='row'>
                <p className='dress-code-text'>Semi-Formal Attire</p>
              </div>
            </div> */}
            <div
              className='image-column col-xl-12 col-lg-12 wow fadeInRight'
              data-wow-delay='200ms'
            >
              <div className='inner-column'>
                <div className='image-box'>
                  <figure className='image overlay-anim'>
                    <img src='assets/images/attire.png' alt='Image' />
                  </figure>
                </div>
                <div className='content-box'>
                  {/* <figure className='image'>
                    <img src='assets/images/icons/icon-cone.png' alt='Image' />
                  </figure> */}
                  <div className='title-box'>
                    <h4 className='title'>The Attire</h4>
                    <div className='row'>
                      <h4 className='subtitle'>Principal Sponsors</h4>

                      <div className='text'>
                        <p className='attire'>Formal Gown</p>
                        <p className='attire-gender mb-2'>for the ladies</p>

                        <p className='attire'>Traditional barong</p>
                        <p className='attire-gender'>for the gentlemen</p>
                      </div>
                    </div>

                    <div className='row'>
                      <h4 className='subtitle'>Guest</h4>
                      <div className='text'>
                        <p className='attire'>Semi-formal Attire</p>
                        <p className='attire-gender mb-2'>
                          for the ladies and gentlemen
                        </p>
                        <p className='color-palletes-text mt-4 mb-0'>
                          We'd love to see you in these colors:
                        </p>
                        <div className='color-palletes'>
                          <figure className='image overlay-anim mx-2'>
                            <img
                              src='assets/images/colors/00.png'
                              alt='Image'
                            />
                          </figure>
                          <figure className='image overlay-anim mx-2'>
                            <img
                              src='assets/images/colors/01.png'
                              alt='Image'
                            />
                          </figure>
                          <figure className='image overlay-anim mx-2'>
                            <img
                              src='assets/images/colors/02.png'
                              alt='Image'
                            />
                          </figure>
                          <figure className='image overlay-anim mx-2'>
                            <img
                              src='assets/images/colors/03.png'
                              alt='Image'
                            />
                          </figure>
                          <figure className='image overlay-anim mx-2'>
                            <img
                              src='assets/images/colors/04.png'
                              alt='Image'
                            />
                          </figure>
                          <figure className='image overlay-anim mx-2'>
                            <img
                              src='assets/images/colors/05.png'
                              alt='Image'
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Details;
