import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Suppliers = () => {
  return (
    <section className='clients-section-two pt-0'>
      <div className='icon-flower-22 bounce-x'></div>
      <div className='auto-container'>
        <div className='sec-title text-center'>
          <h2>Our Suppliers</h2>
        </div>

        <div className='sponsors-outer'>
          <OwlCarousel
            className='clients-carousel owl-carousel owl-theme'
            loop
            margin={10}
            nav
            play
            items={3}
          >
            {/* <ul className='clients-carousel owl-carousel owl-theme'> */}
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/RAKETeaGuiguinto'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/addison.jpeg'
                  alt="Addison's by RAKEŤea"
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/AngelGabrielGarden'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/angel-gabriel-garden.jpeg'
                  alt="Angel Gabriel's Garden"
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a href='https://www.facebook.com/armidiaphoto' target='_blank'>
                <img
                  className='supplier'
                  src='assets/images/suppliers/armidia-photo.jpeg'
                  alt='Armidia Photo'
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/acjbridalbotique'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/camilles-bridal-boutique.png'
                  alt="Camille's Bridal Boutique"
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/casaveraintramuros'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/casa-vera.jpeg'
                  alt='Casa Vera'
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/profile.php?id=100063500543351'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/genesis-creation-events.jpeg'
                  alt='Genesis Creation Events'
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a href='https://www.facebook.com/GentlemensHost' target='_blank'>
                <img
                  className='supplier'
                  src='assets/images/suppliers/gentlemens-host.png'
                  alt=''
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/jhaypee.santos.35'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/jaypee-santos-hair-and-make-up.png'
                  alt='Jaypee Santos Hair and Make Up'
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a href='https://www.facebook.com/Katterpillow' target='_blank'>
                <img
                  className='supplier'
                  src='assets/images/suppliers/katterpillow-crafts.jpeg'
                  alt='Katterpillow'
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/MarbinGarciaCollections'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/marbin-garcia-collections.png'
                  alt='Marbin Garcia Collections'
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/MDCbridalcollection'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/mdc.jpeg'
                  alt='MDC Bridal Collection'
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a href='#'>
                <img
                  className='supplier'
                  src='assets/images/suppliers/michelle-jewelry.png'
                  alt='Michelle Jewelry'
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/RAMProEntertainment'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/rampro-entertainment.jpeg'
                  alt='RAM Pro Entertainment '
                />
              </a>
            </li>
            <li className='client-block-two'>
              <a
                href='https://www.facebook.com/ronaleenbridalboutique'
                target='_blank'
              >
                <img
                  className='supplier'
                  src='assets/images/suppliers/ronaleen-bridal-boutique.jpeg'
                  alt='Ronaleen Bridal'
                />
              </a>
            </li>
          </OwlCarousel>
          {/* </ul> */}
        </div>
      </div>
    </section>
  );
};

export default Suppliers;
