const Gallery = () => {
  return (
    <section className='gallery-section-seven'>
      {/* <div className='icon-flowers-6 bounce-y'></div>
          <div className='icon-leaves-5 bounce-x'></div>
          <div className='icon-leaves-6 bounce-x'></div> */}
      <div className='auto-container'>
        <div className='outer-container'>
          <div className='sec-title style-four'>
            {/* <span className='sub-title'>some beautiful memories</span>
            <h2>Wedding Clicks</h2> */}
            {/* <a href='#' className='view-all'>
              View all photos <i className='icon fa fa-angle-right'></i>
            </a> */}
          </div>
        </div>
        <div className='row g-0'>
          <div className='gallery-block-seven style-two col-lg-4 col-sm-6'>
            <div className='inner-box'>
              <div className='image-box'>
                <figure className='image overlay-anim'>
                  <a
                    href='assets/images/gallery/0.png'
                    className='lightbox-image'
                    data-fancybox='gallery'
                  >
                    <img
                      src='assets/images/gallery/0thumbnail.png'
                      alt='Image'
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>

          <div className='gallery-block-seven col-lg-4 col-sm-6'>
            <div className='inner-box'>
              <div className='image-box'>
                <figure className='image overlay-anim'>
                  <a
                    href='assets/images/gallery/5.png'
                    className='lightbox-image'
                    data-fancybox='gallery'
                  >
                    <img
                      src='assets/images/gallery/5thumbnail.png'
                      alt='Image'
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>

          <div className='gallery-block-seven style-two col-lg-4 col-sm-6'>
            <div className='inner-box'>
              <div className='image-box'>
                <figure className='image overlay-anim'>
                  <a
                    href='assets/images/gallery/1.png'
                    className='lightbox-image'
                    data-fancybox='gallery'
                  >
                    <img
                      src='assets/images/gallery/1thumbnail.png'
                      alt='Image'
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>

          <div className='gallery-block-seven col-lg-4 col-sm-6'>
            <div className='inner-box'>
              <div className='image-box'>
                <figure className='image overlay-anim'>
                  <a
                    href='assets/images/gallery/2.png'
                    className='lightbox-image'
                    data-fancybox='gallery'
                  >
                    <img
                      src='assets/images/gallery/2thumbnail.png'
                      alt='Image'
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>

          <div className='gallery-block-seven style-two col-lg-4 col-sm-6'>
            <div className='inner-box'>
              <div className='image-box'>
                <figure className='image overlay-anim'>
                  <a
                    href='assets/images/gallery/3.png'
                    className='lightbox-image'
                    data-fancybox='gallery'
                  >
                    <img
                      src='assets/images/gallery/3thumbnail.png'
                      alt='Image'
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>

          <div className='gallery-block-seven col-lg-4 col-sm-6'>
            <div className='inner-box'>
              <div className='image-box'>
                <figure className='image overlay-anim'>
                  <a
                    href='assets/images/gallery/4.png'
                    className='lightbox-image'
                    data-fancybox='gallery'
                  >
                    <img
                      src='assets/images/gallery/4thumbnail.png'
                      alt='Image'
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
