import React, { useRef, useState } from 'react';
import Header from './Header';
import Home from './Home';
import Details from './Details';
import Countdown from './Countdown';
import CameraWall from './CameraWall';
import PrincipalSponsors from './PrincipalSponsors';
import SecondarySponsors from './SecondarySponsors';
import RSVP from './RSVP';
import Gallery from './Gallery';
import Suppliers from './Suppliers';
import Map from './Map';
import Footer from './Footer';
import SaveTheDate from './SaveTheDate';
import ReactCountDown from 'react-countdown';

function Main() {
  const [isCompleted, setIsCompleted] = useState(false);

  const renderCameraWall = ({ completed }) => {
    if (completed) {
      setIsCompleted(true);
      return <CameraWall />;
      // Render a completed state
      // return (
      //   <>
      //     <CameraWall />
      //   </>
      // );
    } else {
      // Render a countdown
      setIsCompleted(false);
    }
  };
  const rsvpRef = useRef(null);

  return (
    <>
      <div className='page-wrapper'>
        {/* <div className='preloader'></div> */}
        {/* <Header /> */}
        {/* <div className='hidden-bar-back-drop'></div> */}
        <Home />
        <Countdown rsvpRef={rsvpRef} />
        <SaveTheDate />
        <ReactCountDown
          date={new Date('2024-03-09T05:30:00.000Z').valueOf()}
          renderer={renderCameraWall}
        />
        {/* {isCompleted && <CameraWall />} */}
        <Details />
        <PrincipalSponsors />
        <SecondarySponsors />
        {/* <RSVP ref={rsvpRef} /> */}
        <Gallery />
        <Suppliers />
        <Map />
        <Footer />
      </div>

      <div className='scroll-to-top scroll-to-target' data-target='html'>
        <span className='fa fa-angle-up'></span>
      </div>
    </>
  );
}
export default Main;
