import React, { useState, useRef, useCallback } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { app, storage } from '../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
import ImagePreview from './ImagePreview';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import './Camera.module.css';
import { Check, Clear, Collections } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const DB = getFirestore(app);
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const CameraPage = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('idle');
  const [imgUrl, setImgUrl] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const [progresspercent, setProgresspercent] = useState(0);
  const [isIdealFacingMode, setIsIdealFacingMode] = useState(false);

  const hiddenFileInput = useRef(null);

  const saveToFirebase = async (data) => {
    try {
      const imagesCollection = collection(DB, 'images');
      const imageDoc = doc(imagesCollection);
      setDoc(imageDoc, data);
      setProgresspercent(100);
      // window.alert('Thank you for sharing your memories with us!');
      // reset();

      if (window.confirm('Would you like to visit the memory wall?')) {
        navigate('/gallery');
      } else {
        reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  async function blobToDataUri(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
        setImgUrl(reader.result);
        setStatus('idle');
        setProgresspercent(0);
      };
      reader.onerror = reject;
    });
  }

  const handleTakePhoto = (dataUri) => {
    setImgUrl(dataUri);
    // Do stuff with the photo...
    // console.log('takePhoto');
    // console.log(dataUri);
  };

  const uploadImage = () => {
    const img = dataURItoBlob(imgUrl);
    const file = img;
    if (!file) return;
    setStatus('upload');

    const storageRef = ref(storage, `event/${new Date().valueOf()}.png`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress > 0 ? progress - 1 : progress);
      },
      (error) => {
        setStatus('idle');
        alert(error);
      },
      () => {
        setStatus('complete');

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const metadata = uploadTask.snapshot.metadata;

          setImgUrl(downloadURL);
          const data = {
            downloadUrl: downloadURL,
            message: message,
            createdAt: new Date().valueOf(),
            updatedAt: new Date(metadata.updated).valueOf(),
          };
          saveToFirebase(data);
        });
      }
    );
  };

  const reset = () => {
    setStatus('idle');
    setImgUrl('');
    setFile(null);
    setMessage('');
    hiddenFileInput.current.value = '';
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleSubmit = (e) => {
    console.log(e);
  };

  const changeHandler = (e) => {
    setStatus('preview');
    setProgresspercent(0);
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert('Image mime type is not valid');
      return;
    }

    blobToDataUri(file);

    // setFile(file);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant='determinate' {...props} size='5rem' />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='caption'
            component='div'
            sx={{ fontWeight: 'bold' }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };

  return (
    <div className='camera'>
      <form onSubmit={handleSubmit} className='form'>
        <input
          type='file'
          name='images'
          accept='image/png , image/jpeg, image/webp'
          className='hidden'
          ref={hiddenFileInput}
          onChange={changeHandler}
        />

        <>
          <div className='row image-container'>
            <div className='controls'>
              {status === 'idle' && imgUrl && (
                <div className='message-box'>
                  <div className='input-outer'>
                    <textarea
                      name='message'
                      placeholder='Message'
                      onChange={(e) => setMessage(e.target.value)}
                      rows={5}
                    ></textarea>
                  </div>
                </div>
              )}

              <div className='col-lg-12 actions'>
                {status === 'idle' && imgUrl ? (
                  <div className='actionButtons deleteButton'>
                    <Clear
                      sx={{ fontSize: 30, color: 'white' }}
                      color='danger'
                      className='control delete'
                      size='medium'
                      onClick={reset}
                    />
                  </div>
                ) : (
                  <div />
                )}
                {status === 'idle' && imgUrl ? (
                  <div className='actionButtons uploadButton'>
                    <Check
                      sx={{ fontSize: 60, color: 'white' }}
                      className='control upload'
                      onClick={uploadImage}
                    />
                  </div>
                ) : (
                  <div />
                )}
                {status === 'idle' && (
                  <div className='actionButtons browseButton'>
                    <Collections
                      sx={{ fontSize: 30, color: 'white' }}
                      className='control upload'
                      onClick={handleClick}
                    />
                  </div>
                )}
              </div>

              {(status === 'upload' || status === 'complete') && (
                <>
                  <div
                    className='col-lg-12 actions upload-progress'
                    onClick={() => (progresspercent === 100 ? reset() : '')}
                  >
                    <CircularProgressWithLabel value={progresspercent} />
                  </div>
                  {progresspercent === 100 ? (
                    <div className='col-lg-12 actions upload-progress text-center'>
                      <Typography
                        variant='subtitle1'
                        component='div'
                        color='common.white'
                      >
                        <strong>Tap again to capture new image.</strong>
                      </Typography>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>

            {imgUrl && <ImagePreview dataUri={imgUrl} isFullscreen={true} />}
          </div>
        </>
        {!imgUrl && (
          <Camera
            onTakePhoto={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
            isFullscreen
            isMaxResolution
            // idealResolution={{ width: 768, height: 1024 }}
            sizeFactor={1}
            imageCompression={1}
            idealFacingMode={
              isIdealFacingMode ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT
            }
            onCameraError={() => {
              navigate('/upload');
            }}
          />
        )}
      </form>
    </div>
  );
};

export default CameraPage;
