import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBKjZvY7TmooRkg1413dxsB_thZaSJBHqc',
  authDomain: 'lem-and-ysai.firebaseapp.com',
  projectId: 'lem-and-ysai',
  storageBucket: 'lem-and-ysai.appspot.com',
  messagingSenderId: '323435958719',
  appId: '1:323435958719:web:8db04199956b69448fd10a',
};
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
